

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/trouver-engager-meilleurs-avocats-legal-saint-jerome.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage14 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Comment s’y prendre pour trouver les meilleurs avocats à Saint-Jérôme? - Soumissions Avocat"
        description="Votre avocat à Saint-Jérôme se trouve facilement par l'entremise de cette plateforme gratuite. Qu'il y aille arrestation, comparution, procès ou que vous désiriez simplement un représentant juridique, soyez protégé adéquatement. Obtenez un règlement à l'amiable pour éviter les tribunaux par un maître-négociateur tel qu'un avocat à Saint-Jérôme."
        image={LeadImage}>
        <h1>Comment s’y prendre pour trouver les meilleurs avocats à Saint-Jérôme?</h1><p>Lorsque le besoin d’être représenté par un avocat, vous voudrez faire avec les meilleurs, cela est certain! Les avocats sont des professionnels hautement qualifiés avec qui vous serez en mesure de collaborer dans la pleine mesure de votre volonté et vos capacités. Il n’est cependant pas si simple de trouver le parfait expert qui saura comprendre votre situation, vos besoins et ultimement vous mener vers un dénouement favorable.</p>
<p><StaticImage alt="trouver-engager-meilleurs-avocats-legal-saint-jerome" src="../images/trouver-engager-meilleurs-avocats-legal-saint-jerome.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il vous faut donc connaître les grandes bases de la sphère juridique et de vos droits personnels. C’est de cette façon que vous serez en mesure de communiquer votre situation clairement et qu’un partenariat prospère naîtra. Pour vous aider dans une telle démarche, nous vous proposons tout de même vos principaux droits protégés par le droit qu’un avocat ne négligera pas de faire valoir!</p>
<h2>Quels sont vos droits lors d’une intervention policière?</h2>
<p>Juste parce qu’on vous a mis les menottes aux poignets ne signifie pas que vous n’avez plus de droits. Bien au contraire, la loi prévoit expressément des droits pour les personnes se faisant placer en état d’arrestation. Cela vise à protéger les innocents, les individus se trouvant au mauvais endroit au mauvais moment, mais surtout à garantir une équité procédurale pour toute personne mêlée à un dossier d’accusation criminelle. Si tel est votre cas, vous auriez intérêt à connaître vos principaux droits prévus par la Charte des droits et libertés!</p>
<p><strong>Les motifs de l’arrestation. </strong>Les policiers ont le devoir de vous informer des raisons pour lesquelles vous êtes placés en état d’arrestation ainsi que vous faire part de votre droit au silence ou à un avocat. Ces informations doivent en tout temps être données au moment même de l’arrestation. À défaut de suivre une telle procédure, les accusations pourraient être abandonnées par le procureur de la couronne en raison de la violation des droits fondamentaux d’une personne.</p>
<p><strong>Le droit à un avocat.</strong> Vous avez certainement vu des films dans lesquels les policiers interrogent un individu fraîchement mis en état d’arrestation dans une salle sombre. Vous vous êtes possiblement dit que vous ne souhaiteriez pas être la cible d’un interrogatoire aussi acharné! Sachez que vous avez droit d’être représenté par un avocat dès le moment où vous êtes arrêtés! Attendez de recevoir les conseils de celui-ci avant de commencer à parler, cela pourrait vous éviter bien des ennuis.</p>
<p><strong>Le droit au silence. </strong>Conseil prudent; ne dites rien à la police avant d’avoir consulté un avocat lorsque vous êtes placé en état d’arrestation. Le poste de police n’est pas l’endroit pour raconter votre version des faits, surtout sans assistance juridique! Demeurez plutôt silencieux et contentez-vous d’appeler un avocat dès que possible!</p>
<p><strong>Le droit à l’assistance parentale. </strong>Les adolescents et personnes d’âges mineurs placés en état d’arrestation ont le droit d’être assistés par leurs parents ou leur tuteur. Les policiers sont d’ailleurs tenus d’informer l’adolescent de ce droit. Cela permet d’éviter des abus à l’égard de mineurs placés face à des interrogatoires policiers pour le moins intimidants.</p>
<p><strong>La détention vs. l’arrestation : connaître la différence!</strong> La plupart des gens voient la détention et l’arrestation comme ayant des différences sans réelle distinction. Bien qu’ils soient souvent vus comme des termes interchangeables, les droits des personnes placées dans l’une ou l’autre de ces situations diffèrent largement.</p>
<p>Pour ce qui est de la détention, elle comprend un élément physique et psychologique de contrainte. La personne qui est <strong>détenue par les policiers</strong> ne croit pas avoir la possibilité de partir. Cependant, il est possible d’être détenu sans être mis en état d’arrestation. Cela est notamment le cas lorsqu’un policier vous intercepte pour un excès de vitesse; vous n’êtes pas en état d’arrestation, mais vous n’avez pas la possibilité de partir tant que le constat d’infraction ne vous a pas été remis.</p>
<p>En revanche, le policier devra vous indiquer clairement lorsque vous êtes <strong>mis en état d’arrestation</strong>, du moins, en théorie. La réalité est que certains policiers jouent sur les zones grises du droit et de la déontologie et abusent de l’ignorance de la population en général pour obtenir des confessions ou des réponses. En effet, certaines personnes laissent des policiers les emmener au poste et les questionner sans avocat alors qu’ils ne sont pas à faire aucune de ces choses.</p>
<p><strong>Dans le doute, demandez aux policiers si vous êtes réellement placés en état d’arrestation et, si tel est le cas, exigez les services d’un avocat sur-le-champ! Vous pourriez éviter bien des soucis se faisant!</strong></p>
<h2>Que se passe-t-il si vous êtes accusé au criminel?</h2>
<p>Si jamais les conseils de la section précédent ne vous ont pas épargné d’une arrestation menant à une accusation criminelle, vous devrez vous familiariser avec les grandes étapes du processus. Il serait également fort utile de connaître le peu de portes qu’il vous reste pour vous sortir d’impasse! Voici donc le parcours que vous serez probablement forcés d’entreprendre avec avocat criminaliste!</p>
<p><StaticImage alt="processus-accusation-criminelle-systeme-justice-saint-jerome" src="../images/processus-accusation-criminelle-systeme-justice-saint-jerome.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Arrestation : </strong>Tout commence avec la mise en état d’arrestation. C’est un policier qui se charge de cette étape et emmène l’individu au poste de police. Deux dénouements sont susceptibles de se produire après cette étape. Soit la personne se verre remettre une citation à comparaître et pourra repartir chez elle, soit elle sera jugée dangereuse et gardée en détention jusqu’au jour de sa comparution.</p>
<p><strong>Comparution :</strong> La personne accusée d’un crime doit se présenter devant un juge ou y être escortée lorsqu’elle faisait l’objet d’une détention afin d’entendre les faits qui lui sont reprochés et pour enregistrer un plaidoyer. Un plaidoyer de culpabilité évitera évidemment la tenue d’un procès et il faudra passer directement à l’observation sur la peine qu’un juge devra établir. En revanche, un plaidoyer de non-culpabilité mènera vers la prochaine étape du processus et évidemment vers la tenue d’un procès.</p>
<p><strong>Audience remise en liberté provisoire :</strong> Pour qu’une telle audience ait lieu, il faut que l’individu ait préalablement été mis en détention par les policiers au moment de son arrestation et que le procureur de la Couronne soit contre l’idée de libérer la personne détenue. Le juge décidera en se basant sur la protection de la victime, de la société en général et des risques que la personne représente si elle est relâchée dans la société. La décision n’est pas tout blanc tout noir, cependant. Il est possible qu’un juge rattache des conditions à la remise en liberté, tel qu’un couvre-feu, des endroits prohibés, etc.</p>
<p><strong>Enquête préliminaire :</strong> L’enquête préliminaire vise à déterminer si les preuves accumulées par la Couronne sont suffisantes pour justifier la tenue d’un procès. C’est d’ailleurs à ce moment que la Couronne est tenue de divulguer l’ensemble de sa preuve (elle le fait aussi à la comparution), afin que le juge en apprécie la qualité. Lorsque le juge jugera suffisamment convaincante la preuve amassée, il ordonnera la tenue d’un procès. Dans le cas contraire, la personne accusée sera déchargée et libérée si elle faisait l’objet d’une détention.</p>
<p><strong>Procès et jugement :</strong> Les crimes les plus graves tels que le meurtre seront automatiquement envoyés devant la Cour supérieure pour être jugés devant jury. C’est d’ailleurs la seule instance devant laquelle des procès devant jury peuvent se tenir. Pour les autres crimes graves, l’accusé aura le choix d’être jugé par un juge seul ou accompagné d’un jury.</p>
<p>Dans ce dernier cas, le juge agit comme arbitre du droit, alors que le jury apprécie les faits et rend un verdict en analysant la preuve présentée. Lorsque le procès est devant un juge seulement, c’est celui-ci qui aura le fardeau tout entier de rendre une décision. Évidemment, dans les deux cas, c’est un verdict de culpabilité ou de non-culpabilité qui ressort d’un procès.</p>
<p><strong>Représentation sur la peine :</strong> Lorsqu’un verdict de culpabilité aura été l’issue du procès, les avocats de la défense et de la couronne retourneront devant le juge pour débattre de la peine qui devrait être appliquée. Lorsque les deux parties s’entendent, ils peuvent soumettre une proposition conjointe au juge que ce dernier n’a cependant aucune obligation de respecter. En cas contraire, chacune de parties peut émettre ses propres propositions pour tenter de convaincre le juge. C’est ici qu’engager un avocat plaideur peut faire une grande différence sur la peine à purger.</p>
<p><strong>Appel (si possible ou autorisé) :</strong> Lorsqu’une personne déclarée coupable croit que le juge s’est trompé dans l’application du droit ou qu’il a admis des éléments de preuve qui n’auraient pas dû l’être, il peut saisir la Cour d’appel pour que la décision de sa cause soit révisée.</p>
<p><strong>Quelle est la différence entre une infraction sommaire et un acte criminel?</strong> Une infraction sommaire est un crime que la loi considère comme état d’une gravité moindre (ex : Vol, fraude d’un faible montant d’argent, indécence publique, etc.). De telles infractions se règlent avec moins de formalités que le processus précédent; il n’y a pas d’enquête préliminaire effectuée lors d’une infraction sommaire. En revanche, les actes criminels sont ceux d’une gravité plus importante au sens de la loi.</p>
<h2>Un règlement à l’amiable est-il envisageable en droit criminel?</h2>
<p>En droit civil, les parties peuvent toujours décider, d’un commun accord, de mettre fin à leur différend hors cour, même si l’instance est déjà entamée. La même chose ne saurait être dite en droit criminel, mais il y a toujours une possibilité de s’entendre avec le procureur de la couronne pour éviter un procès, enregistrer un plaidoyer de culpabilité et bénéficier d’une sentence réduite.</p>
<p><StaticImage alt="reglement-hors-cour-entente-accusation-criminelle-avocat-saint-jerome" src="../images/reglement-hors-cour-entente-accusation-criminelle-avocat-saint-jerome.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Conférence préparatoire au procès : lieux de discussion propice!</strong> La conférence préparatoire est l’une des étapes avant un procès se tenant devant un jury. Elle se tient entre les avocats de chacune des parties et en présence du juge également. Son objectif est de régler les points importants sur la tenue du procès et vise aussi à évaluer la possibilité d’admettre certains faits allégués pour diminuer le fardeau. Ce moment est aussi propice pour discuter d’une entente pour éviter un procès en échange d’une peine réduite!</p>
<p><strong>L’entente peut être conclue en tout temps, même pendant le procès.</strong> Le début du procès n’annule pas la possibilité de conclure le même type d’entente que mentionné précédemment. À tout moment, et ce jusqu’au verdict, il demeure possible de conclure un accord avec le procureur de la couronne.</p>
<p>Cela est d’ailleurs très probable, car après les premières journées de plaidoirie, une des parties constate généralement son désavantage par rapport à l’autre. Il est évident que la Couronne détient le « gros bout du bâton » dans la plupart des cas, mais une négociation peut tout de même sauver plusieurs années d’emprisonnement!</p>
<p>Pour minimiser les conséquences d’une accusation criminelle sur vous, mais surtout sur votre famille, engagez un avocat en droit criminel et pénal sans tarder! C’est votre futur qui est en jeu après tout!</p>
<h2>L’ordonnance de protection : comment vous protège-t-elle?</h2>
<p>Une ordonnance de protection vise à vous protéger d’une autre personne, même si celle-ci n’a été reconnue d’aucun crime. Vous devrez vous adresser à la Cou supérieure avec l’aide d’un avocat pour obtenir une telle ordonnance. Vous devrez cependant démontrer que vous avez de réelles raisons de craindre pour votre santé ou votre sécurité et qu’une personne précise est responsable de cette crainte. Fournir des documents en guise de preuve aidera à obtenir l’ordonnance. Mais qu’est-ce qu’une telle ordonnance peut faire en termes de protection?</p>
<p><strong>Prévient des blessures infligées par une autre personne :</strong> En limitant le champ d’action d’une autre personne à votre égard, vous limitez grandement les chances d’être blessé physiquement par celle-ci. C’est aussi vos biens et votre propriété que vous mettez à l’abri de cet individu avec une telle ordonnance!</p>
<p><strong>Force un individu à cesser un comportement:</strong> Un juge peut exiger d’un individu qu’il cesse de vous contacter, de vous harceler ou encore d’être accompagné d’une autre personne lorsqu’elle doit entrer en contact avec vous.</p>
<p><strong>Quelles sont les conséquences d’une ordonnance de protection?</strong> Des sanctions pénales s’appliquent à quiconque viole les termes d’une telle ordonnance. La sanction peut prendre la forme d’une amende, d’une peine d’emprisonnement de moins d’un an ou encore des travaux communautaires.
<strong>
Si vous croyez que votre sécurité est mise en péril par les agissements d’une personne que vous connaissez, ne tardez pas à contacter un avocat pour mettre en place une telle ordonnance dès que possible!</strong></p>
<h2>L’avocat en droit du travail : quels services peut-il vous offrir?</h2>
<p>Le droit criminel n’est pas la seule branche du droit qui revête d’une certaine importance, celui du travail aussi! En effet, s’il y a bien une chose que la quasi-totalité de la population a en commun est la pratique d’un métier ou d’une profession dans un milieu de travail. Tout le monde devrait connaître ses droits de base en matière de travail et les services qu’un avocat peut offrir pour aider!</p>
<p><StaticImage alt="avocat-droit-travail-harcelement-negociation-syndicale-saint-jerome" src="../images/avocat-droit-travail-harcelement-negociation-syndicale-saint-jerome.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Négociation syndicale :</strong> Vous souhaitez mettre en place un syndicat dans votre milieu de travail? Vous avez tenté de présenter le projet aux autres employés, mais un membre du patronat vous met des bâtons dans les roues? Il s’agit là d’une pratique formellement interdite qui est même protégée par la Charte canadienne des droits et libertés en vertu du droit d’association. Un avocat peut donc très bien entreprendre un recours pour faire cesser pareille intrusion et même négocier les termes de la convention collective une fois en place.</p>
<p><strong>Griefs : </strong>Une fois la convention collective mise en place, les disputes et les désaccords entre les dirigeants et les salariés devront passer par les représentants syndicaux chargés d’entreprendre les griefs. Ces recours sont exercés par le syndicat au nom de l’employé qui porte plainte contre l’un de ses patrons.  Le syndicat doit avoir une excellente raison pour refuser d’entreprendre un grief; lorsque le refus est justifié, vous pouvez tout de même vous tourner vers un avocat pour de l’aide à obtenir gain de cause!</p>
<p><strong>Harcèlement psychologique :</strong> Depuis maintenant quelques années, les employeurs ont l’obligation de créer un environnement de travail exempt de toxicité et de harcèlement psychologique. Un tel comportement peut se manifester par un seul geste d’une gravité exemplaire ou par plusieurs gestes irritants causant une détresse psychologique chez un ou plusieurs employés.</p>
<p>Le harcèlement psychologique n’est pas obligé de provenir de l’employeur pour que celui-ci fasse l’objet d’une poursuite; le milieu de travail est sa responsabilité et, selon la loi, un employeur ne peut invoquer la défense de l’ignorance pour justifier qu’un cas de harcèlement s’est produit sous sa supervision. C’est le devoir de l’employeur d’être au courant!</p>
<p><strong>Discrimination avant/pendant l’emploi :</strong> Refuser d’embaucher quelqu’un en raison d’un manque de qualifications est tout à fait justifié. Refuser d’embaucher pour un motif discriminatoire, c’est une autre histoire! Les avocats prenant en charge des causes de discrimination sont en mesure de prouver que des pratiques discriminatoires ont eu lieu en milieu de travail. Engagez-les si vous croyez avoir été la cible de tels abus!</p>
<p><strong>Clauses de non-concurrence : </strong>Les employeurs tentent parfois de protéger leurs intérêts en limitant le mouvement de leurs employés d’une entreprise à une autre. Cela se fait principalement par l’entremise d’une clause de non-concurrence. Celle-ci limite le type de travail pouvant être effectué, ainsi que l’endroit et la durée de temps où ce même travail ne peut être pratiqué. Ces clauses ne peuvent toutefois pas être abusives! Si vous êtes liées par une clause de concurrence de plusieurs années, vous pourriez potentiellement vous en défaire avec l’aide d’un avocat!</p>
<h2>Pourquoi avez-vous besoin d’un avocat à Saint-Jérôme lors d’un accident de travail?</h2>
<p>Quel que soit votre milieu de travail ou le type de métier que vous exercez, vous n’êtes pas à l’abri d’un accident de travail ou d’une maladie professionnelle. Lorsqu’un tel incident survient, il est crucial d’agit rapidement afin que vous obteniez l’indemnité à laquelle vous avec le droit sans connaître d’embuches.</p>
<p><strong>Saviez-vous qu’au Québec, ce n’est pas votre employeur qui vous indemnise, mais plutôt la CNESST? </strong>En effet, le régime d’indemnisation des accidents de travail ressemble à celui des accidents automobiles de la SAAQ en ce sens qu’il s’agit d’un régime sans égard à la responsabilité ce quiconque. Votre employeur paie des primes à la CNESST et c’est cette dernière qui s’occupe de vous verser un montant en cas d’accident.</p>
<p><strong>Il arrive toutefois que les employeurs contestent les accidents invoqués par leurs employés! </strong>Quelle que soit la raison derrière leur contestation, il se peut que votre employeur s’oppose à votre réclamation auprès de la CNESST. C’est là que les services d’un avocat qualifié prendront tout leur sens!</p>
<p>Il y a fort à parier que votre employeur tentera de prouver que l’accident n’est pas réellement arrivé sur les lieux de travail ou qu’il découle d’une cause externe à votre poste dans son entreprise. Ce sera le rôle de votre avocat de vous représenter, mais sachez que dans un tel dossier, c’est votre employeur qui a le fardeau de prouver que vous n’avez pas été victime d’un accident de travail!</p>
<p><strong>Légalement, comment définit-on un accident de travail? </strong>Un tel accident fait en fait partie de la catégorie plus large de ce qu’on qualifie de « lésion professionnelle », c’est-à-dire, une blessure ou une maladie survenant à l’occasion de la prestation de travail. Plus précisément, on définira l’accident de travail comme l’évènement soudain et imprévu menant à une lésion professionnelle.</p>
<p>Le principal avantage du régime de la Loi sur les accidents de travail et des maladies professionnelles est qu’elle créée une foule de présomptions jouant en la faveur de l’employé réclamant et il incombe à l’employeur de les faire tomber. Par exemple, un accident qui survient sur les lieux de travail est présumé être une lésion professionnelle et l’employeur qui prétend le contraire aura le fardeau de la renverser.</p>
<p><strong>Existe-t-il des accidents de travail qui ne sont pas indemnisables? </strong>Une des seules restrictions au droit à l’indemnisation prévu par la loi est celle de la négligence grossière et volontaire. En effet, lorsqu’il est prouvé que l’employé réclamant une indemnisation a subi le préjudice en question non pas en raison d’un incident soudain et imprévu, mais plutôt par le résultat de sa propre négligence grossière, son droit à une indemnité tombera. Le seul droit qui sera préservé dans une telle situation de négligence est celui d’être indemnisé en cas de décès du travailleur.</p>
<p><strong>Comment se déroulera le recours lors d’un accident de travail? </strong>Lorsqu’une décision de la CNESST ne satisfait pas le travail ou lorsque l’employeur s’oppose à la réclamation demandée, c’est devant le Tribunal administratif du travail que la cause sera portée. Le juge aura donc le rôle de départager les droits des parties.</p>
<p><strong>Votre employeur peut-il vous sanctionner lorsque vous subissez un accident de travail? </strong>Absolument pas! Que ce soit avant d’obtenir l’indemnisation convoitée ou après, votre employeur ne peut en aucun cas vous faire subir de représailles simplement parce que vous avez exercé votre droit de demander une indemnité.</p>
<p><strong>Le droit du travail, surtout en ce qui concerne l’indemnisation pour un accident de travail ou une lésion professionnelle, est un domaine complexe et hautement spécialisé au sein de la pratique du droit. Pour obtenir votre réparation, demandez l’aide d’un avocat en droit du travail en remplissant le formulaire de demande de soumission! </strong></p>
<h2>Quoi faire lorsque vous faites l’objet d’un congédiement sans cause juste et suffisante?</h2>
<p>Se faire congédier est une expérience choquante, quels que soient les motifs qui justifient la mise à pied. Toutefois, il est encore plus frustrant de se faire montrer la porte lorsqu’on sait pertinemment qu’on n’a rien à se reprocher. C’est la raison pour laquelle la loi prévoit des sanctions à l’encontre des employeurs qui s’adonnent à des pratiques de congédiement illégales. Il faut cependant être prudent au moment de qualifier un congédiement comme étant sans cause juste et suffisante.</p>
<p><StaticImage alt="Avocat pour congédiement illégal et accident de travail" src="../images/avocat-congediement-illegal-accident-travail-saint-jerome.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage>)</p>
<p><strong>
Quelle différence entre être « congédié » et « licencié »? </strong>Tout d’abord, les employeurs sont en droit de mettre un terme au contrat de travail de leurs employés pour des raisons économiques, tel un chiffre d’affaires en déclin, une restructuration corporative ou autre motif du même ordre. C’est ce qu’on qualifie de licenciement au sens de la Loi sur les normes du travail.</p>
<p>En revanche, le <strong>congédiement</strong> est directement lié aux performances de l’employé ou à son rendement déficient. Cela n’est toutefois pas une disposition passe-partout permettant à l’employeur de montrer la porte à tout employé qu’il juge inefficace. La loi prévoit explicitement que le motif devra toujours être sérieux et justifié lorsqu’on congédie un employé, mais une distinction est à faire avec celui qui justifie plus de deux ans de service.</p>
<p><strong>Deux ans et plus de service : une protection additionnelle! </strong>En effet, le fait d’avoir travaillé pendant deux ans ou plus pour le même employeur confère à l’employé une protection de plus qui est celle de ne pas pouvoir être congédié, à moins que l’employeur ne justifie un motif sérieux ainsi qu’une cause juste et suffisante. Une telle barrière additionnelle n’existe pas pour les employés justifiant moins de deux ans de service et certains recours prévus dans la Loi sur les normes du travail ne leur sont pas ouverts.</p>
<p><strong>Le recours pour congédiement sans cause juste et suffisante </strong>est habituellement porté devant le Tribunal administratif du travail et il arrive même que ce soit la CNESST qui entreprenne le recours au nom du salarié. Pour les travailleurs qui ne peuvent se prévaloir de cette option, les tribunaux ordinaires demeurent ouverts pour entendre leur cause.</p>
<h2>Qu’est-ce qu’une plainte pour pratique interdite au travail?</h2>
<p>En plus de l’interdiction de congédier un employé sans motif suffisant, les employeurs ont aussi l’obligation de respecter une certaine ligne de conduite au sein de leurs pratiques de gestion. Mais quel type de pratiques sont interdites et protégées par la loi? Voici quelques exemples!</p>
<p>Les <strong>femmes enceintes</strong> bénéficient, avec raison, d’une protection légale qui empêche les employeurs de les congédier pour la simple raison de leur grossesse. Cette protection s’étend à toute rétrogradation ou modification de poste motivé par cette grossesse.</p>
<p>Ensuite, il est également interdit pour un employeur de sanctionner de quelque façon que ce soit un employé qui réclame une indemnité légale qui lui est due. La  même chose peut être dite concernant le refus de faire des heures additionnelles si les obligations parentales du salarié lui en empêchent.</p>
<p><strong>Quel est le délai pour porter plainte? </strong>Lorsqu’un salarié est victime d’une pratique illégale, il dispose d’un délai de 45 jours pour porter plainte auprès de la CNESST qui analysera la recevabilité de la plainte avant d’y donner suite. Si celle-ci juge la plainte recevable, il reviendra à votre employeur de justifier la légalité de la sanction appliquée.</p>
<h2>Comment identifier le domaine de droit qui s’applique à votre cause?</h2>
<p>Rattacher rapidement votre cause à l’un des principaux domaines du droit Québécois et Canadien facilitera vos recherches, tout en vous permettant de bénéficier des services d’experts. En vous en tenant aux grands domaines du droit, vous risques de trouver l’avocat qui vous convient sans problème!</p>
<p><StaticImage alt="domaine-pratique-droit-familial-jeunesse-propriete-intellectuel" src="../images/domaine-pratique-droit-familial-jeunesse-propriete-intellectuel.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Droit familial : </strong>Demandes de divorce, de garde d’enfant, de partage du patrimoine familial et établissement de la pension alimentaire. Ce sont tous des recours qui peuvent être entrepris par un avocat en droit de la famille.</p>
<p><strong>Droit du louage :</strong> Pris dans une dispute de bail résidentiel? Votre propriétaire néglige les lieux et ne vous fournit pas un espace habitable adéquat? Ne vous faites pas brimer dans vos droits aussi fondamentaux que ceux garantissant un logement adéquat.</p>
<p><strong>Propriété intellectuelle :</strong> Vous avez inventé quelque chose de toute pièce et vous l’êtes fait usurper? Croyez-le ou non, la loi prévoit des recours spécialement pour les dossiers de propriété intellectuelle. Les avocats travaillant dans ce domaine ont des compétences très aiguisées permettant de prouver une atteinte somme toute difficile à établir à vos droits.</p>
<p><strong>Droit des contrats :</strong> Quelle que soit la forme de l’entente conclue, du moment qu’elle comprend tous éléments d’un contrat valable en droit, vous devriez consulter un avocat pour faire respecter l’entente.</p>
<p><strong>Droit de la jeunesse :</strong> parfois considéré comme une branche du droit de la famille, le droit de la jeunesse vise les recours affectant spécialement les jeunes enfants, tels que les dossiers de déchéance de l’autorité parentale, d’adoption, mais surtout les recours impliquant l’intervention du Directeur de la protection de la jeunesse (DPJ).</p>
<p><strong>Quel que soit votre problème, il y a toujours un avocat disponible pour vous aider dans les alentours de Saint-Jérôme! Il suffit de les contacter avant que la situation ne s’aggrave!</strong></p>
<h2>Pour trouver les meilleurs avocats à Saint-Jérôme, demandez l’aide de Soumissions Avocat!</h2>
<p>Pris avec un litige entre les mains ou une accusation criminelle pèse dans la balance? Ne craignez rien, un avocat compétent situé à Saint-Jérôme saura vous sortir d’impasse en un rien de temps. Il vous suffit simplement de les contacter avant que votre situation n’aille de mal en pis!</p>
<p>C’est justement avec cette étape que Soumissions Avocat peut vous aider! Notre vaste réseau de partenaires avocats vous permettra de bénéficier des meilleurs conseils juridiques dans la province.</p>
<p><strong>Il ne suffit que de remplir le formulaire au bas de la page pour que votre demande soit acheminée à 3 avocats dans la région de Saint-Jérôme!</strong></p>
<p><strong>En plus d’être gratuit, notre service ne comprend que les meilleurs avocats, alors pourquoi attendre!</strong></p>
<p>Dernière modification: 8 août 2020</p>
    </SeoPage>
)
export default ContentPage14
  